



























































import Vue, { VueConstructor } from 'vue'
import BalanceCard from '@/components/BalanceCard.vue'
import Organization from '../../../../../store/models/Organization'
import AccountMixin from '../../../../../mixins/accountMixin'

interface Interest {
  id: number
  creditLine: string
  initiatorName: string
  dateTime: string
  benefitGenerated: string
  expectedInterest: string
}

interface ComponentData {
  items: Interest[]
  columns: Record<string, string>[]
  currentItemId: string | number | null
}

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof AccountMixin>
>).extend({
  components: {
    BalanceCard
  },

  mixins: [AccountMixin],

  data(): ComponentData {
    return {
      items: [],
      columns: [
        {
          label: 'Ligne de crédit',
          field: 'creditLine'
        },
        {
          label: 'Nom Initiateur',
          field: 'initiatorName'
        },
        {
          label: 'Date/Heure',
          field: 'dateTime'
        },
        {
          label: 'Intérêt Généré',
          field: 'benefitGenerated'
        },
        {
          label: 'Intérêt Attendu',
          field: 'expectedInterest'
        },
        {
          label: 'Action',
          field: '_action',
          align: 'right'
        }
      ],
      currentItemId: ''
    }
  },

  computed: {
    organization(): Organization {
      return Organization.loggedIn()
    }
  },

  methods: {
    onSeeDetails(id: any) {
      this.$router.push({
        name: 'interests.details',
        params: { interestId: id }
      })
    }
  }
})
